import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
}
`;

export const logo = styled.img`
  width: 15vw;
  margin: 10vh 0 5vh;
`;
