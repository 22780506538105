import React, { useState } from 'react';
import * as S from './TitleIntro.styled';
import heartWhite from '../../assets/img/heart.svg';
import heartGreen from '../../assets/img/heartGreen.svg';

type Props = {
  text: string,
};

const TitleIntro = ({ text }: Props) => {
  const [hover, setHover] = useState(false);

  const isHover = () => {
    setHover(!hover);
  };

  return (
    <S.ContainerIcon onMouseEnter={isHover} onMouseLeave={isHover}>
      <S.Icon src={hover ? heartGreen : heartWhite} />
      <S.TextIcon> {text} </S.TextIcon>
    </S.ContainerIcon>
  );
};

export default TitleIntro;
