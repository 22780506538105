import React, { useEffect, useState } from 'react';
import * as S from './App.style';
import Banner from './component/Banner';
import Header from './component/Header';
import Loadign from './component/Loadign';
import Project from './component/Project';
import Container from './component/Container';
import About from './component/About';
import Plans from './component/Plans';
import Footer from './component/Footer';

function App() {
  const [load, setLoad] = useState(true);

  const handler = (event) => {
    setLoad(true);
  };
  useEffect(() => {
    window.addEventListener('load', handler);

    return () => window.removeEventListener('load', handler);
  }, []);
  return load ? (
    <S.Body>
      <Header />
      <S.ContainerConnect>
        <Banner />
        <Project />
      </S.ContainerConnect>
      <S.ContainerDefault>
        <About />
      </S.ContainerDefault>
      <S.ContainerPoints>
        <Plans />
      </S.ContainerPoints>
      <Footer />
    </S.Body>
  ) : (
    <Container>
      <Loadign load={load} />
    </Container>
  );
}

export default App;
