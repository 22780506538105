import styled, { css } from 'styled-components';
import { TabColorEnum } from '../../data/types';
import { theme } from '../../Global/styles/globalStyles';

type Props = {
  background: string,
};

const getFontColor = ({ background }: Props) => {
  switch (background) {
    case TabColorEnum.GREEN:
      return css`
        font-family: nexaBold;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 13px;
        color: ${theme.colors.darkGrey};
      `;
    case TabColorEnum.NONE:
      return css`
        font-family: nexaRegular;
        font-size: 15px;
        line-height: 18px;
        color: ${theme.colors.white};
      `;
    case TabColorEnum.WHITE:
      return css`
        font-family: nexaRegular;
        font-size: 15px;
        line-height: 18px;
        color: ${theme.colors.green};
      `;
    default:
      return css`
        font-family: nexaRegular;
        font-size: 15px;
        line-height: 18px;
        color: ${theme.colors.white};
      `;
  }
};

const getBackground = ({ background }: Props) => {
  switch (background) {
    case TabColorEnum.GREEN:
      return css`
        background-color: ${theme.colors.green};
        border-radius: 20px;
        :hover {
          background-color: ${theme.colors.darkGrey};
        }
        :hover p {
          color: ${theme.colors.white};
        }
      `;
    case TabColorEnum.NONE:
      return css`
        background-color: ${theme.colors.darkGrey};
        border-radius: 20px;
        :hover {
          background-color: ${theme.colors.green};
        }
        :hover p {
          color: ${theme.colors.darkGrey};
        }
      `;
    case TabColorEnum.DARK:
      return css`
        background-color: ${theme.colors.darkGrey};
        :hover {
          background-color: ${theme.colors.green};
        }
        :hover p {
          color: ${theme.colors.darkGrey};
        }
      `;
    default:
      return css`
        background-color: ${theme.colors.white};
        :hover {
          background-color: ${theme.colors.darkGrey};
        }
        :hover p {
          color: ${theme.colors.white};
        }
      `;
  }
};

export const Button = styled.button`
  padding: 1px 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border: 1px solid ${theme.colors.green};
  ${getBackground}
  cursor: pointer;
  padding: 1vh 1vw;
`;

export const Text = styled.p`
  ${getFontColor}
  text-align: center;
  text-transform: uppercase;
`;
