import React from 'react';
import logo from '../../assets/img/logoHorizontal.png';
import { TabColorEnum } from '../../data/types';
import Button from '../Button';
import Link from '../Link';
import Container from '../Container';
import * as S from './Header.css';

function Header() {
  return (
    <Container isHeader={true}>
      <S.Header className="Header">
        <S.logo src={logo} className="App-logo" alt="logo" />
        <S.Nav>
          <Link url="#projects" text="projetos" isHeader />
          <Link url="#Plans" text="Planos" isHeader />
          <Link url="#about" text="Sobre nós" isHeader />
        </S.Nav>
        <Button text="Contate-nos" color={TabColorEnum.GREEN} />
      </S.Header>
    </Container>
  );
}

export default Header;
