import styled from 'styled-components';
import { theme } from '../../Global/styles/globalStyles';

interface Props {
  isGif: boolean;
}

const getDisplay = ({ isGif }: Props) => {
  return isGif ? 'none' : 'flex';
};

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
`;

export const Col =
  styled.div <
  Props >
  `
  display: flex;
  width: 50%;
  height: auto;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: stretch;

  @media (max-width: 768px) {
    display: ${getDisplay};}
    width: 100%;
  }
  
`;

export const Title = styled.h2`
  font-family: nexaBold;
  font-style: normal;
  font-weight: 700;
  font-size: 71px;
  line-height: 71px;
  animation: animate 1.5s linear infinite;
  margin-bottom: 8vh;

  @keyframes animate {
    0% {
      color: ${theme.colors.black};
    }
    25% {
      color: ${theme.colors.black};
    }
    75% {
      color: ${theme.colors.green};
    }
    100% {
      color: ${theme.colors.green};
    }
  }
`;

export const Text = styled.p`
  color: ${theme.colors.white};
  font-family: nexaRegular;
  font-size: 1em;
  padding: 1vh;
`;

export const Image = styled.img``;

export const ContainerButton = styled.div`
  margin-top: 6vh;
  width: 50%;
`;
