import React from 'react';
import * as S from './MainCard.css';

type Props = {
  title: string,
  subtitle: string,
  descriptionGreen: string,
  description: string,
};

const MainCard = ({
  title,
  subtitle,
  descriptionGreen,
  description,
}: Props) => {
  const buy = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=5511993332805&text=Ol%C3%A1%20quero%20contratar%20o%20plano%20${title}%20para%20minha%20empresa.%20`,
      '_blank' // <- This is what makes it open in a new window.
    );
  };
  return (
    <S.Container>
      <S.Title> {title} </S.Title>
      <S.Emphasis>
        <S.Icon />
        <S.EmpashisPhrase> + de 86% das escolhas </S.EmpashisPhrase>
      </S.Emphasis>
      <S.Hr />
      <S.Text>{subtitle}</S.Text>
      <S.Hr />
      <S.TextWhite>{descriptionGreen}</S.TextWhite>
      <S.Hr />
      <S.Text>{description}</S.Text>
      <S.Button onClick={buy}>
        <S.TextButton> Contratar Agora</S.TextButton>
      </S.Button>
    </S.Container>
  );
};

export default MainCard;
