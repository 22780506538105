import styled, { css } from 'styled-components';

type Props = {
  isHeader: boolean,
  isFooter: boolean,
};

const getPadding = ({ isHeader, isFooter }: Props) => {
  if (isHeader) {
    return css`
      padding: 0 5vw;
      margin-bottom: 8vh;
    `;
  } else if (isFooter) {
    return css`
      backgground-color: #242827;
    `;
  } else {
    return css`
      padding: 0 5vw;
      @media (min-width: 768px) {
        width: 100%;
      }
    `;
  }
};

export const Container = styled.div`
  ${getPadding}
`;
