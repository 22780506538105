import React from 'react';
import * as S from './Button.css';

type Props = {
  text: string,
  color: string,
};

const Button = ({ text, color }: Props) => {
  const contact = () => {
    window.open(
      'https://api.whatsapp.com/send?phone=5511993332805&text=Ol%C3%A1%2Cdesejo%20mais%20informa%C3%A7%C3%B5es%20sobre%20seus%20servi%C3%A7os%20',
      '_blank'
    );
  };

  return (
    <S.Button background={color} onClick={contact}>
      <S.Text background={color}> {text} </S.Text>
    </S.Button>
  );
};

export default Button;
