import React from 'react';
import * as S from './Link.css';
import { ReactComponent as WebIcon } from '../../assets/img/web.svg';

type Props = {
  url: string,
  text: string,
  isHeader?: boolean,
};

const Link = ({ url, text, isHeader = false }: Props) => {
  return (
    <S.A href={url} target="_blank" isHeader={isHeader}>
      <S.Icon>
        <WebIcon />
      </S.Icon>
      <S.text> {text} </S.text>
    </S.A>
  );
};

export default Link;
