import styled, { css } from 'styled-components';
import { theme } from '../../Global/styles/globalStyles';

type Props = {
  isHeader?: boolean,
};

const handleHeader = ({ isHeader }: Props) => {
  if (isHeader) {
    return css`
      svg {
        display: none;
      }
      :hover h2 {
        color: ${theme.colors.green};
      }
    `;
  } else {
    return css`
      display: flex;
      flex-direction: row;
      align-items: center;
      transition: all 500ms;
      background-color: ${theme.colors.green};
      :hover {
        background-color: ${theme.colors.white};
        color: ${theme.colors.green};
      }
      :hover * {
        color: ${theme.colors.green};
        fill: ${theme.colors.green};
      }
      :hover {
        fill: ${theme.colors.green};
      }
    `;
  }
};

export const A = styled.a`
  text-decoration: none;
  padding: 0 1vw;
  margin: 2vh 0 0;
  ${handleHeader}
`;

export const Icon = styled.div`
  width: 1em;
  padding: 0;
  * {
    width: 1em;
    height: 1em;
  }
`;

export const text = styled.h2`
  font-family: nexaBold;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  text-transform: uppercase;
  color: ${theme.colors.white};
  padding: 10px;
`;
