import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  padding: 20px 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const logo = styled.img`
  width: 10vw;
  height: 5vh;

  @media (max-width: 768px) {
    width: 30vw;
    height: 4vh;
  }
`;
