import React from 'react';
import { TabColorEnum } from '../../data/types';
import Button from '../Button';
import Container from '../Container';
import * as S from './About.css';
import Wifi from '../../assets/img/wifi.gif';

const About: React.FC = () => {
  return (
    <Container>
      <div id="about" />
      <S.Row>
        <S.Col isGif={false}>
          <S.Title>
            QUEM <br /> SOMOS?
          </S.Title>
          <S.Text>
            Nossa startup é especializada no desenvolvimento de software
            personalizado, com o objetivo de fornecer soluções inovadoras e
            acessíveis a empresas e indivíduos de todos os tamanhos. Nossa
            missão é oferecer uma experiência única e autêntica, fornecendo
            software sob medida para atender às necessidades específicas de cada
            cliente.
          </S.Text>
          <S.Text>
            Nós valorizamos a colaboração e a transparência em todas as etapas
            do processo, desde a concepção até a entrega final do software.
            Buscamos sempre a excelência em qualidade, mantendo-nos atualizados
            em relação às tendências do mercado e tecnologias emergentes.
          </S.Text>
          <S.Text>
            Acreditamos que a tecnologia pode ser uma ferramenta poderosa para
            aprimorar a vida das pessoas e empresas. Por isso, nossa equipe está
            empenhada em fornecer soluções de software personalizadas que
            atendam aos mais altos padrões de desempenho, usabilidade e
            acessibilidade.
          </S.Text>
          <S.ContainerButton>
            <Button text="Entre em contato" color={TabColorEnum.NONE} />
          </S.ContainerButton>
        </S.Col>
        <S.Col isGif={true}>
          <S.Image src={Wifi} />
        </S.Col>
      </S.Row>
    </Container>
  );
};

export default About;
