import React, { useEffect, useState } from 'react';
import logo from '../../assets/img/logo.png';
import Progress from 'react-progressbar';
import * as S from './Loading.css';

interface Load {
  load: boolean;
}

function Loading(prop: Load) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (prop.load) {
      setProgress(100);
    }
  }, []);
  return (
    <S.Box>
      <S.logo src={logo} className="App-logo" alt="logo" />
      <Progress completed={progress} color="#02fe85" height="3px" />
    </S.Box>
  );
}

export default Loading;
