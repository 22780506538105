import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 68px 33px;
  gap: 30px;
  width: 312px;
  height: 572px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
`;

export const Title = styled.h3`
  font-family: 'nexaBold';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 25px;
  color: #02fe85;
`;

export const Hr = styled.hr`
  width: 100%;
  height: 1px;
  background: #d9d9d9;
  flex: none;
  order: inherit;
  flex-grow: 0;
`;

export const Text = styled.p`
  font-family: 'nexaRegular';
  font-size: 15px;
  line-height: 18px;

  /* color2 */

  color: #282829;
`;
export const TextGreen = styled.p`
  font-family: 'nexaBold';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;

  /* color1 */

  color: #00934c;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  width: 180px;
  height: 39px;
  background: #282829;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  :hover {
    background: #00934c;
    box-shadow: 7px 4px 5px rgb(0 0 0 / 58%);
  }
`;

export const TextButton = styled.p`
  font-family: 'nexaRegular';
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
`;
