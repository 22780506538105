import React from 'react';
import Container from '../Container';
import TitleIntro from '../TitleIntro';
import Card from './component/Card';
import * as S from './Project.css';
import dgweather from '../../assets/img/dgweather.png';
import supripel from '../../assets/img/supripel.png';
import dfmidias from '../../assets/img/dfmidias.png';

const Projec: React.FC = () => {
  return (
    <Container>
      <div id="projects" />
      <TitleIntro text="Projetos recentes:" />
      <S.Wrapper>
        <Card
          image={dgweather}
          title={'DGWeather'}
          subtitle={
            'Obtenha informações precisas e atualizadas sobre o clima em sua região com nosso site de previsão do tempo. Com dados de fontes confiáveis, você poderá se preparar adequadamente para qualquer situação meteorológica e aproveitar ao máximo seu dia. Visite-nos agora e confira!'
          }
          descript={
            'Veja qual o clima da sua  região no seu idioma local. Acompanhe a previsão do tempo e avisos meteorológicos.'
          }
          link={'http://dgweather.site'}
        />
        <Card
          image={supripel}
          title={'Supripel'}
          subtitle={
            'Encontre tudo o que você precisa para a volta às aulas em nosso site de venda de material escolar. Com uma ampla seleção de produtos de alta qualidade e preços acessíveis, oferecemos uma experiência de compra conveniente e confiável. Faça suas compras agora e comece o ano letivo com o pé direito!'
          }
          descript={
            'E-commerce seguro, para você e seus clientes comprarem seu material escolar.'
          }
          link={'http://supripel.com/'}
        />
        <Card
          image={dfmidias}
          title={'DF MIDIAS SOCIAIS'}
          subtitle={
            'Maximize o potencial de sua marca na internet com nossos serviços de marketing digital e mídias sociais. Nossa empresa oferece soluções personalizadas para atingir seus objetivos de negócios e expandir sua presença online. Deixe-nos ajudá-lo a alcançar o sucesso no mundo digital. Entre em contato conosco hoje mesmo!'
          }
          descript={
            'Uma solução personalizada para atingir seus objetivos de negócios e expandir sua presença online.'
          }
          link={'https://dfmidiassociais.com/'}
        />
      </S.Wrapper>
    </Container>
  );
};

export default Projec;
