import React from 'react';
import Container from '../Container';
import * as S from './Footer.css';

const Footer = () => {
  return (
    <Container isFooter>
      <S.FooterContainer>
        <S.Title>Estamos sempre prontos para te atender nos canais:</S.Title>
        <S.Text>
          Desenvolvemos soluções digitais que impulsionam seu negócio. Entre em
          contato conosco e descubra como podemos ajudar sua empresa a crescer
          com o desenvolvimento de software, aplicativos ou sites
          personalizados. Vamos trabalhar juntos para transformar sua ideia em
          realidade!
        </S.Text>
        <S.Whatsapp href="https://api.whatsapp.com/send?phone=5511993332805&text=Quero%20um%20software%20personalizado">
          Envie uma mensagem{''}
        </S.Whatsapp>
        <S.Email href="mailto:contato@gomescode.com"> Envie um email</S.Email>
      </S.FooterContainer>
      <S.Row>
        <S.AllResearch>Todos os direitos reservados</S.AllResearch>
      </S.Row>
    </Container>
  );
};

export default Footer;
