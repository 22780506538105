import { createGlobalStyle } from 'styled-components';
import nexaBold from '../../assets/fonts/nexa-bold/nexa-bold.otf';
import nexaRegular from '../../assets/fonts/nexa-regular/nexaregular.otf';

export const theme = {
  colors: {
    white: '#ffffff',
    darkGrey: '#282829',
    black: '#141414',
    green: '#02FE85',
  },
};

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: nexaBold;
    src: url(${nexaBold}) format('opentype');
    font-weight: bold;
    font-style: bold;
  }
  @font-face {
    font-family: nexaRegular;
    src: url(${nexaRegular}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }
`;

export default GlobalStyle;
