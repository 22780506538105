import React, { useEffect, useState } from 'react';
import * as S from './Card.css'
import Icon from '../../../../assets/img/icon.svg'
import Arrow from '../../../../assets/img/down.svg'
import Link from '../../../Link';
import WhatsApp from '../../../WhatsApp';

type Props = {
  image: string;
  title: string;
  subtitle: string;
  descript: string;
  link: string
}

const useOutsideClick = (callback) => {
  const ref = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    const handleClick = (event) =>{
      if(ref.current && !ref.current.contains(event.target)){
        callback();
      }
    };
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    }
  }, [ref])
  return ref;
}

const Card = ({image, title, subtitle, descript,link}:Props ) => {
  const [hover, setHover] = useState(false);
  const [expand, setExpand] = useState(false);
  
  const isHover = () => {
    setHover(!hover)
  }

  const handleClick = () => {
    setExpand(!expand)
  }


  const handleClickOutside = () => {
    setExpand(false);
  }

  const ref: any = useOutsideClick(handleClickOutside);

  return (
  
      <S.Card
        ref={ref}
        onMouseEnter={isHover}
        onMouseLeave={isHover}
        changeColor={hover}
        expand={expand}
        onClick={handleClick}
      >
        <S.RowTitle>
          <S.DivTitle>
            <S.Icon src={Icon} />
            <S.Title> {title} </S.Title>
          </S.DivTitle>
          <S.ArrowIcon src={Arrow} expand={expand} />
        </S.RowTitle>
        <S.Row>
          <S.Image src={image} />
          <S.Text isText={true} expand={expand} changeColor={hover}> {subtitle}  </S.Text>
        </S.Row>
        <S.Row>
          <S.Details expand={expand}>
            <S.Text isText={true} expand={expand}>
            {descript}
            </S.Text>
            { link.length > 0 ? 
              <Link url={link}  text='Acesse aqui'/> :
              <></>
          }
            
          </S.Details>
        </S.Row>
      </S.Card>
  );
}

export default Card;