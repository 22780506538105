import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import favicon from './assets/img/logo192.png';
import Favicon from 'react-favicon';
import { ParallaxProvider } from 'react-scroll-parallax';
import GlobalStyle from './Global/styles/globalStyles';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ParallaxProvider>
    <Favicon url={favicon} />
    <GlobalStyle/>
    <App />
    </ParallaxProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
