import React from 'react';
import * as S from './Container.css';

type Props = {
  isHeader?: boolean,
  isFooter?: boolean,
};

const Container = ({
  isHeader = false,
  isFooter = false,
  children,
}: React.PropsWithChildren<Props>) => {
  return (
    <S.Container isHeader={isHeader} isFooter={isFooter}>
      {children}
    </S.Container>
  );
};

export default Container;
