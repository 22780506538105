import styled from 'styled-components';
import trophy from '../../../../assets/img/trofeu.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 78.0256px 37.8654px;
  gap: 34.42px;
  width: 358px;
  height: 674.76px;
  background: linear-gradient(180deg, #02fe85 0%, #01a858 100%);
  box-shadow: 0px 0px 11.4744px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const Title = styled.h3`
  font-family: 'nexaBold';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: #fff;
`;

export const Emphasis = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  width: 68%;
  height: auto;
  background: #282829;
  flex-wrap: nowrap;
`;

export const Icon = styled.div`
  background-image: url(${trophy});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 11%;
  padding: 18px 0px;
  margin: 0;
`;

export const EmpashisPhrase = styled.h4`
  font-family: 'nexaBold';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  color: #fff;
`;

export const Hr = styled.hr`
  width: 100%;
  height: 1px;
  background: #d9d9d9;
  flex: none;
  order: inherit;
  flex-grow: 0;
`;

export const Text = styled.p`
  font-family: 'nexaRegular';
  font-size: 15px;
  line-height: 18px;
  color: #282829;
`;
export const TextWhite = styled.p`
  font-family: 'nexaBold';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  width: 180px;
  height: 39px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  :hover {
    background: #282829;
    box-shadow: 7px 4px 5px rgb(0 0 0 / 78%);
  }
`;

export const TextButton = styled.p`
  font-family: 'nexaRegular';
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #02fe85;
`;
