import styled, { css } from 'styled-components';
import { theme } from '../../../../Global/styles/globalStyles';

interface Props {
  changeColor?: boolean;
  expand: boolean;
  isText?: boolean;
}

const getColor = ({ changeColor, expand, isText = false }: Props) => {
  if (expand) {
    if (isText) {
      return css`
        color: ${theme.colors.white};
      `;
    } else {
      return css`
        background-color: ${theme.colors.darkGrey};
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
      `;
    }
  } else if (changeColor && !expand) {
    if (isText) {
      return css`
        color: ${theme.colors.white};
      `;
    } else {
      return css`
        background-color: ${theme.colors.darkGrey};
      `;
    }
  } else {
    if (isText) {
      return css`
        color: ${theme.colors.black};
      `;
    } else {
      return css`
        background-color: ${theme.colors.white};
      `;
    }
  }
};

const getAnimationArrow = ({ expand }: Props) => {
  if (!expand) {
    return css`
      opacity: 1;
    `;
  } else {
    return css`
      opacity: 1;
      transform: rotate(180deg);
    `;
  }
};

const handleExpand = ({ expand }: Props) => {
  if (!expand) {
    return css`
      height: 0;
      opacity: 0;
      transform: translate(0, -35%);
    `;
  } else {
    return css`
      height: auto;
      opacity: 1;
      padding: 5vh 0;
      transform: translate(0, 0);
    `;
  }
};

export const Card =
  styled.div <
  Props >
  `
  height: auto;
  width: auto;
  padding:2% 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  ${getColor}
  box-shadow: 0px 0px 4.02602px rgba(0, 0, 0, 0.25);
  transition-duration: 800ms;
  
  @media (max-width:768px){
    padding:8% 5%;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const RowTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5%;
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
`;

export const Col = styled.div``;

export const Icon = styled.img`
  width: 2em;
`;

export const ArrowIcon = styled.img`
  width: 1em;
  transition: all 2000ms;
  ${getAnimationArrow}
`;

export const Title = styled.h4`
  font-family: nexaBold;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 25px;
  padding-left: 5px;
  color: ${theme.colors.green};
`;

export const Text =
  styled.p <
  Props >
  `
  padding-left: 50px;
  ${getColor}
  @media (max-width: 768px) {
    padding-left: 0;
    margin-top: 20px  
    font-size: 2em;
  }
`;

export const Image = styled.img`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  transition: all 1500ms;
  ${handleExpand}
`;

export const Button = styled.button``;
