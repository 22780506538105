import React from 'react';
import * as S from './Plans.css';
import Card from './component/Card';
import Container from '../Container';
import MainCard from './component/MainCard';
import TitleIntro from '../TitleIntro';

const Plans: React.FC = () => {
  return (
    <Container>
      <div id="Plans" />
      <S.Row>
        <Card
          title={'Platinuum'}
          subtitle={
            'O nosso plano Platinuum de manutenção de sites, no valor de R$ 499,00, inclui 4 horas de trabalho mensais dedicadas à manutenção do seu site.'
          }
          descriptionGreen={
            'Limpamos seu site e verificamos a integridade dos arquivos, removemos malwares'
          }
          description={
            'Além disso, nosso plano Platinuum oferece suporte técnico rápido e eficiente, para que você possa resolver quaisquer problemas que possam surgir de forma ágil e eficaz.'
          }
        />
        <MainCard
          title={'Gold'}
          subtitle={
            'Nosso plano Gold de manutenção de sites por R$699,00 oferece um pacote completo de serviços de manutenção e suporte técnico, incluindo 6 horas de trabalho mensais dedicadas à manutenção do seu site.'
          }
          descriptionGreen={
            'Com este plano, você pode contar com nossa equipe de especialistas para realizar atualizações, correções de bugs e outras melhorias em seu site, para garantir o seu bom funcionamento.'
          }
          description={
            'Além disso, nosso plano Gold oferece relatórios mensais detalhados sobre o desempenho do seu site, incluindo métricas como tráfego, engajamento e conversões, para que você possa tomar decisões mais informadas sobre o desenvolvimento do seu site.'
          }
        />
        <Card
          title={'Diamond'}
          subtitle={
            'Nosso plano Diamond de manutenção de sites por R$989,00 oferece um pacote completo de serviços de manutenção e suporte técnico, com foco no desempenho do seu site.'
          }
          descriptionGreen={
            'Com este plano, nossa equipe dedicará 8 horas mensais para realizar ajustes e otimizações necessárias para garantir que seu site carregue rapidamente e ofereça uma experiência de usuário superior.'
          }
          description={
            'Além disso, nosso suporte técnico especializado está sempre disponível para resolver quaisquer problemas que possam afetar o desempenho do seu site, para que você possa se concentrar em seus negócios.'
          }
        />
      </S.Row>
    </Container>
  );
};

export default Plans;
