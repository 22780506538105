import styled from 'styled-components';

export const FooterContainer = styled.div`
  background-color: #141414;
  padding: 5vh 5vw;
  height: 100%;
`;
export const Title = styled.h2`
  font-family: 'nexaBold';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: #ffffff;
`;

export const Text = styled.p`
  font-family: 'nexaRegular';
  color: #ffffff;
  font-size: 15px;
  line-height: 18px;
  padding: 1vh 0;
`;
export const Whatsapp = styled.a`
  font-family: 'nexaBold';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
  padding-right: 10px;
`;

export const Email = styled.a`
  font-family: 'nexaBold';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background: #242827;
`;

export const AllResearch = styled.p`
  font-family: 'nexaBold';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  text-transform: uppercase;
  color: #ffffff;
`;
