import styled from 'styled-components';
import { theme } from '../../Global/styles/globalStyles';

export const ContainerIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: max-content;
  padding: 1vh 1vw;
  background: ${theme.colors.darkGrey};
  margin-bottom: 1vh;
`;

export const Icon = styled.img`
  width: 1vw;
  padding-right: 5px;

  @media (max-width: 768px) {
    width: 5vw;
  }
`;

export const TextIcon = styled.h5`
  margin: 0;
  font-family: nexaRegular;
  font-size: 15px;
  color: ${theme.colors.white};
  white-space: nowrap;
`;
