import styled from 'styled-components';
import background from './assets/img/radial.svg';
import world from './assets/img/imgBase.svg';
import connect from './assets/img/conexao.svg';
import { theme } from './Global/styles/globalStyles';

export const Body = styled.div`
  background-image: url(${background});
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
`;
export const ContainerConnect = styled.div`
  background-image: url(${world});
  background-position-x: right;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: 50%;
  padding: 0 0 8vh;
`;

export const ContainerDefault = styled.div`
  background-color: ${theme.colors.darkGrey};
  height: 100%;
  padding: 8vh 0;
`;

export const ContainerPoints = styled.div`
  background-image: url(${connect});
  background-position-x: right;
  background-position-y: center;
  background-repeat: repeat-x;
  background-size: 50%;
  padding: 0 0 8vh;
`;
