import React from 'react';
import Typewriter from 'typewriter-effect';
import TitleIntro from '../TitleIntro';
import * as S from './Banner.css';
import Container from '../Container';

function Banner() {
  return (
    <Container>
      <TitleIntro text="Perfeito para você" />
      <S.Box>
        <S.Text>
          {' '}
          Apresentamos soluções{' '}
          <S.Span>
            <Typewriter
              options={{
                strings: ['tecnológicas', 'inovadoras', '👾🤖🤓👩‍💻👨‍💻'],
                autoStart: true,
                loop: true,
                cursor: '',
              }}
            />
          </S.Span>{' '}
          para o seu negócio
        </S.Text>
      </S.Box>
    </Container>
  );
}

export default Banner;
