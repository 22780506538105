import styled from 'styled-components';
import { theme } from '../../Global/styles/globalStyles';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  height: 50vh;
  width: 40%;
`;

export const Text = styled.h3`
  font-family: nexaBold;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: ${theme.colors.white};
`;
export const Span = styled.span`
  div span {
    background-color: ${theme.colors.green};
  }
`;
